@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --brand-mer-50: hsla(209, 79%, 89%, 1);
        --brand-mer-100: hsla(208, 79%, 83%, 1);
        --brand-mer-200: hsla(209, 79%, 78%, 1);
        --brand-mer-300: hsl(209, 78%, 72%);
        --brand-mer-400: hsl(209, 78%, 61%);
        --brand-mer-500: hsla(209, 86%, 44%, 1);
        --brand-mer-600: hsla(209, 100%, 40%, 1);
        --brand-mer-700: hsla(208, 100%, 35%, 1);
        --brand-mer-800: hsla(209, 100%, 26%, 1);
        --brand-mer-900: hsla(209, 100%, 19%, 1);
        --brand-mer-1000: hsla(209, 100%, 13%, 1);

        --brand-lagoon-50: hsla(199, 100%, 93%, 1);
        --brand-lagoon-100: hsla(198, 100%, 90%, 1);
        --brand-lagoon-200: hsla(199, 100%, 86%, 1);
        --brand-lagoon-300: hsla(199, 100%, 83%, 1);
        --brand-lagoon-400: hsla(199, 100%, 76%, 1);
        --brand-lagoon-500: hsla(199, 100%, 66%, 1);
        --brand-lagoon-600: hsla(199, 75%, 60%, 1);
        --brand-lagoon-700: hsla(199, 58%, 53%, 1);
        --brand-lagoon-800: hsla(199, 51%, 40%, 1);
        --brand-lagoon-900: hsla(199, 51%, 30%, 1);
        --brand-lagoon-1000: hsla(200, 51%, 20%, 1);

        --brand-sun-50: hsla(48, 89%, 93%, 1);
        --brand-sun-100: hsla(46, 93%, 89%, 1);
        --brand-sun-200: hsla(47, 92%, 85%, 1);
        --brand-sun-300: hsla(47, 92%, 81%, 1);
        --brand-sun-400: hsla(47, 91%, 74%, 1);
        --brand-sun-500: hsla(47, 92%, 63%, 1);
        --brand-sun-600: hsla(47, 70%, 57%, 1);
        --brand-sun-700: hsla(47, 55%, 50%, 1);
        --brand-sun-800: hsla(47, 54%, 38%, 1);
        --brand-sun-900: hsla(47, 54%, 28%, 1);
        --brand-sun-1000: hsla(47, 54%, 19%, 1);

        --neutral-50: hsla(0, 0%, 97%, 1);
        --neutral-100: hsla(225, 12%, 93%, 1);
        --neutral-200: hsla(223, 12%, 89%, 1);
        --neutral-300: hsla(222, 12%, 84%, 1);
        --neutral-400: hsla(222, 12%, 78%, 1);
        --neutral-500: hsla(222, 12%, 72%, 1);
        --neutral-600: hsla(221, 12%, 64%, 1); /* Light font */
        --neutral-700: hsla(221, 11%, 56%, 1);
        --neutral-800: hsl(223, 11%, 36%); /* Body font */
        --neutral-900: hsla(222, 13%, 26%, 1);
        --neutral-1000: hsla(218, 14%, 11%, 1); /* Dark font */

        --light-blue: hsla(206, 54%, 97%, 1);
        --light-blue-foreground: var(--neutral-800);

        --background: hsl(0 0% 100%);
        --foreground: var(--neutral-800);
        --foreground-light: var(--neutral-600);
        --foreground-dark: var(--neutral-1000);

        --card: hsl(0 0% 100%);
        --card-foreground: var(--neutral-800);

        --popover: hsl(0 0% 100%);
        --popover-foreground: var(--neutral-800);

        --primary: var(--brand-mer-800);
        --primary-foreground: hsla(0, 0%, 100%, 1);

        --secondary: var(--neutral-100);
        --secondary-foreground: var(--brand-mer-600);

        --muted: hsl(210 40% 96.1%);
        --muted-foreground: var(--neutral-500);

        --accent: var(--neutral-100);
        --accent-foreground: var(--brand-mer-500);

        --destructive: hsl(0 84.2% 60.2%);
        --destructive-foreground: hsl(210 40% 98%);

        --border: hsla(223, 12%, 89%, 1);
        --input: hsl(214.3 31.8% 91.4%);
        --ring: var(--primary);

        --light: hsla(0, 0%, 97%, 1);
        --light-foreground: hsl(222.86, 11.48%, 35.88%);

        --player-state: hsla(218, 14%, 11%, 0.85);
        --player-state-alt: hsla(218, 14%, 32%, 0.85);
        --player-state-onair: hsla(0, 100%, 46%, 1);

        --radius: 0.5rem;
    }
}

@layer base {
    * {
        @apply border-border;
    }

    body {
        @apply bg-background text-foreground;
    }
}

/* Composant "Avis Décès" */
.avis-deces-bg {
    /* Mobile */
    background: url('/avis-deces-sub.svg') no-repeat calc(100% + 60px) 0/contain,
    url('/avis-deces.jpg') no-repeat center/cover;
    /* PC */
    @media (min-width: 768px) {
        background: url('/avis-deces-sub.svg') no-repeat 100% 0,
        url('/avis-deces.jpg') no-repeat center/cover;
    }
}

.banner-histoire-radio-bg {
    background: url('/banner-histoire-radio-sub.svg') no-repeat calc(100% + 20px) 0/contain,
    url('/histoire-radio.jpg') no-repeat center/cover;
    @media (min-width: 768px) {
        background: url('/banner-histoire-radio-sub.svg') no-repeat 100% 0,
        url('/histoire-radio.jpg') no-repeat center/cover;
    }
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: "";
}

.bg-emission-illustration {
    --emission-illustration: '';
    background: hsl(0deg, 0%,90%) var(--emission-illustration) no-repeat center / cover;
    background-blend-mode: screen;
}

.children-overflow-visible *{
    overflow: visible;
}

.p-slider {
    padding-inline: 1rem;
    @media (min-width: 768px) {
        padding-inline: 1.5rem;
    }
    @media (min-width: 1536px) {
        padding-inline: 6vw;
    }
    @media (min-width: 1636px) {
        padding-inline: 9vw;
    }
    @media (min-width: 1736px) {
        padding-inline: 11vw;
    }
    @media (min-width: 1836px) {
        padding-inline: 14vw;
    }
    @media (min-width: 1936px) {
        padding-inline: 15vw;
    }
}

.carousel-gradient-l::before {
    z-index: 1;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 7vw;
    background: linear-gradient(to left, transparent, hsl(0,0%,100%));
    pointer-events: none;
}

.carousel-gradient-r::after {
    z-index: 1;
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 7vw;
    background: linear-gradient(to right, transparent, hsl(0,0%,100%));
    pointer-events: none;
}

.btn-filter {
    padding: 4px 8px;
    position: relative;
    &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 3px;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        transition: background-color ease-in-out .3s;
        background-color: transparent;
    }
    &:hover:after, &:focus:after {
        background-color: var(--brand-mer-200);
    }
    &.active:after {
        background-color: var(--brand-mer-500);
    }
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background: url('/icon-clock.png') no-repeat calc(100% - 12px) 50%;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width:auto;
}

.article-wrapper {
    h3 {
        font-size: 1.5rem;
        margin-bottom: .25rem;
    }
    h4 {
        font-size: 1.25rem;
        margin-bottom: .25rem;
    }
}


.bg-gradient-promoted {
    background: radial-gradient(76.17% 78.91% at 3.52% 96.82%, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 100%) transparent 50% / cover no-repeat;
}

.bg-quote {
    background: url('/quote-open.png') no-repeat top left, url('/quote-close.png') no-repeat right bottom;
    @media (max-width: 768px) {
        background-size: 32px;
    }
}

.ads-wrapper > iframe {
    max-width: 100%;
}